$font-family-base: 'Circular-Pro-Book-S', 'Roboto', sans-serif;
$font-family-book: 'Circular-Pro-Book-S', 'Roboto', sans-serif;
$font-family-medium: 'Circular-Pro-Medium-S', 'Roboto', sans-serif;
$font-family-bold: 'Circular-Pro-Bold-S', 'Roboto', sans-serif;

$font-size-xxxxxlarge: 50px;
$font-size-xxxxlarge: 40px;
$font-size-xxxlarge: 30px;
$font-size-xxlarge: 25px;
$font-size-xlarge: 22px;
$font-size-large: 20px;
$font-size-regular: 18px;
$font-size-medium: 16px;
$font-size-small: 14px;
$font-size-xsmall: 12px;
$font-size-xxsmall: 10px;

$font-size-5xlarge: $font-size-xxxxxlarge;
$font-size-4xlarge: $font-size-xxxxlarge;

$font-size-button: 16px;
